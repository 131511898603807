import {
  getCategories,
  getTPASettingsIsPostPageSplitEnabled,
  getTags,
  resolveId,
} from '@wix/communities-blog-client-common';
import { AppDataWithSubjects } from '../../../viewer.app';
import { fetchPostMetadata } from '../../common/actions/fetch-post-metadata';
import {
  getIsRecentPostsEnabled,
  getIsRelatedPostsEnabled,
} from '../../common/selectors/app-settings-selectors';
import {
  isSSR,
  isSeo,
} from '../../common/store/basic-params/basic-params-selectors';
import { fetchProfileUrls } from '../../common/store/profile-urls/profile-urls-actions';
import { AppStore } from '../../common/types';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';
import { initRelatedPostsWidget, initCommentsWidget } from './connections';

export async function fetchRecentPostsAndComments({
  store,
  post,
  connections,
  appData,
}: {
  store: AppStore;
  post: any;
  connections: any;
  appData: AppDataWithSubjects;
}) {
  const state = store.getState();
  const isClient = !isSSR(state);
  const dispatches = [];
  const relatedPostsEnabled = getIsRelatedPostsEnabled(state) !== false;
  const recentPostsEnabled = getIsRecentPostsEnabled(state);
  const hasRelatedPosts = post.relatedPostIds && post.relatedPostIds.length > 0;

  const isPostPageSplitEnabled = getTPASettingsIsPostPageSplitEnabled(state);
  if (isPostPageSplitEnabled) {
    appData?.subjects.postPageRenderModel.next({
      post,
      categories: getCategories(state),
      tags: getTags(state),
    });

    await Promise.all([
      initRelatedPostsWidget(connections, post),
      initCommentsWidget(connections, post._id),
    ]);
  }

  dispatches.push(() => store.dispatch(fetchPostMetadata(resolveId(post))));
  dispatches.push(() => store.dispatch(fetchProfileUrls()));

  if (recentPostsEnabled || (!hasRelatedPosts && relatedPostsEnabled)) {
    dispatches.push(() => store.dispatch(fetchRecentPosts(post)));
  }

  if (relatedPostsEnabled && hasRelatedPosts) {
    dispatches.push(() => store.dispatch(fetchRelatedPosts(post)));
  }

  if (isSeo(state)) {
    await Promise.all(dispatches.map((dispatch) => dispatch()));
  } else if (isClient) {
    dispatches.forEach((dispatch) => dispatch());
  }
}
