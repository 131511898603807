import { AppDataWithSubjects, PostPageRenderModel } from '../../viewer.app';
import model from './model';

const getHeaderData = (postPageRenderModel: PostPageRenderModel) => {
  return {
    title: postPageRenderModel.post.title,
    categories: postPageRenderModel.categories,
    rating: postPageRenderModel.post.averageRating,
    numRatings: postPageRenderModel.post.totalRatings,
    writer: postPageRenderModel.post.owner?.name ?? '',
    firstPublishedDate: Date.parse(postPageRenderModel.post.firstPublishedDate),
    timeToRead: postPageRenderModel.post.timeToRead,
    lastPublishedDate: Date.parse(postPageRenderModel.post.lastPublishedDate),
    likeCount: postPageRenderModel.post.likeCount || 0,
    viewCount: postPageRenderModel.post.viewCount || 0,
    commentCount: postPageRenderModel.post.totalComments || 0,
    tags: postPageRenderModel.tags,
  };
};

// TODO: problem - correct data is shown on 2nd render
export default model.createController((params) => {
  const { $w, $widget, flowAPI } = params;
  const appData = params.appData as AppDataWithSubjects;

  return {
    pageReady: async () => {
      appData?.subjects.postPageRenderModel.subscribe((next) => {
        const { data } = next;
        const headerDataParams = getHeaderData(data);

        $w('#categories1').children[0].children[0].options =
          headerDataParams.categories;

        $w('#title1').children[0].children[0].text = headerDataParams.title;

        $w('#ratings1').children[0].children[0].rating =
          headerDataParams.rating;
        $w('#ratings1').children[0].children[0].numRatings =
          headerDataParams.numRatings;

        $w('#writer1').children[0].children[1].text = headerDataParams.writer;

        $w('#metadata1').children[0].children[0].children[0].text =
          headerDataParams.firstPublishedDate;
        $w('#metadata1').children[0].children[0].children[2].text =
          headerDataParams.timeToRead;
        $w('#metadata1').children[0].children[0].children[4].text =
          headerDataParams.lastPublishedDate;

        $w('#counters1').children[0].children[1].children[1].text =
          headerDataParams.likeCount;
        $w('#counters1').children[0].children[2].children[1].text =
          headerDataParams.viewCount;
        $w('#counters1').children[0].children[3].children[1].text =
          headerDataParams.commentCount;

        $w('#tags1').children[0].children[0].options = headerDataParams.tags;
      });

      $widget.fireEvent('widgetLoaded', {});
    },
    exports: model.getExports(),
  };
});
