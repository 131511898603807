import { IPlatformAPI, InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import { NormalizedCategory } from '@wix/communities-blog-client-common';
import { NormalizedPost } from './external/common/types';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => makeSubjectsForAppData(platformApis);

export type PostPageRenderModel = {
  post: NormalizedPost;
  categories: NormalizedCategory[];
  tags: BlogTag[];
};

function makeSubjectsForAppData(platformApis: IPlatformAPI) {
  return {
    subjects: {
      postPageRenderModel: {
        next: (post: PostPageRenderModel) =>
          platformApis.pubSub.publish('postChange', post, true),
        subscribe: (
          callback: (next: {
            data: PostPageRenderModel;
            name: 'postChange';
            origin: 'worker';
          }) => void,
        ) => {
          const id = platformApis.pubSub.subscribe(
            'postChange',
            callback,
            true,
          );

          return {
            unsubscribe: () =>
              platformApis.pubSub.unsubscribe('postChange', id),
          };
        },
      },
    },
  };
}

export type AppDataWithSubjects =
  | ReturnType<typeof makeSubjectsForAppData>
  | undefined;
