import { Reducer } from 'redux';
import { Post } from '../../controller/related-posts-api';
import { SET_MAIN_POST } from './main-post-actions';

export const mainPostReducer: Reducer<Post> = (state, action) => {
  switch (action.type) {
    case SET_MAIN_POST:
      return action.payload;
    default:
      return state ?? null;
  }
};

export type MainPostState =
  | ReturnType<typeof mainPostReducer>
  | undefined
  | null;
