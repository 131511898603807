import { Store } from 'redux';
import { setMainPost } from '../store/main-post';

export type Post = {
  id: string;
  relatedPostIds: string[];
  categoryIds: string[];
};

export type SetMainPost = (post: Post) => Promise<void>;

export type RelatedPostsApi = {
  setMainPost: SetMainPost;
};

export type CreateRelatedPostsApi = {
  store: Store;
};

export function createRelatedPostsApi({
  store,
}: CreateRelatedPostsApi): RelatedPostsApi | {} {
  return {
    setMainPost: (post) => {
      if (post) {
        store.dispatch(setMainPost(post));
      }
    },
  };
}
