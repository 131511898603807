import {
  createAction,
  buildPaginationRequestParams,
  SECTION_CATEGORY,
  ENTITY_TYPE_POSTS,
  BLOG_HEADER_CURSOR,
  urijs,
  getCategoryIds,
} from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { getPageSize, getCursor } from '../selectors/pagination-selectors';
import { getHeader } from '../services/get-header';
import { getTotalResults } from '../services/pagination';
import { normalizePosts } from '../services/post-utils';

export const FETCH_TAG_POSTS_REQUEST = 'tagPosts/FETCH_REQUEST';
export const FETCH_TAG_POSTS_SUCCESS = 'tagPosts/FETCH_SUCCESS';
export const FETCH_TAG_POSTS_FAILURE = 'tagPosts/FETCH_FAILURE';

export const fetchTagPostsRequest = createAction(FETCH_TAG_POSTS_REQUEST);
export const fetchTagPostsSuccess = createAction(
  FETCH_TAG_POSTS_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchTagPostsFailure = createAction(
  FETCH_TAG_POSTS_FAILURE,
  (payload) => payload,
  (payload, meta) => meta,
);

/** @deprecated Remove after EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER/EDITOR is merged  */
export function fetchTagPosts({
  tagId,
  page = 1,
  pageSize: defaultPageSize,
  featuredOnly,
  excludeContent,
}) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchTagPostsRequest({ tagId, page }));

    const cursor = getCursor(getState(), ENTITY_TYPE_POSTS);
    const pageSize = getPageSize(getState(), {
      overrideSettingsPageSize: defaultPageSize,
      section: SECTION_CATEGORY,
    });
    const params = buildPaginationRequestParams(page, pageSize, cursor);

    const promise = request(
      urijs('/_api/posts').query({
        tagIds: tagId,
        featuredOnly,
        excludeContent,
        ...params,
      }),
      {
        parseHeaders: true,
      },
    );

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchTagPostsSuccess(
            normalizePosts({
              state: getState(),
              posts: body,
              blogCategoryIds: getCategoryIds(getState()),
            }),
            {
              tagId,
              page,
              entityCount: getTotalResults(headers),
              pageSize,
              cursor: getHeader(headers, BLOG_HEADER_CURSOR),
            },
          ),
        ),
      )
      .catch(({ status }) =>
        dispatch(
          fetchTagPostsFailure({ error: { status }, tagId, page }, { tagId }),
        ),
      )
      .then(() => promise);
  };
}

/** @deprecated Remove after EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER/EDITOR is merged  */
export const fetchTagPostsPromisified = createPromisifiedAction(
  fetchTagPosts,
  () => null,
  (response) => response.status,
);
