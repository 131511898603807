import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post-types';
import {
  getVideoCount,
  getImageCount,
  getCharacterCount,
  hasHtml,
  getGalleries,
} from '../content-selectors';
import { isEdited, categoryIds, isPublished } from '../event-helpers';

const handler = (action) => {
  const post = action.payload;
  return {
    evid: 203,
    is_edited: isEdited(post),
    video_count: getVideoCount(post.content),
    image_count: getImageCount(post.content),
    character_count: getCharacterCount(post.content),
    used_html: hasHtml(post.content),
    galleries: getGalleries(post.content),
    category_ids: categoryIds(post),
    is_published: isPublished(post),
    post_stable_id: post.id,
    eventMeta: {
      description: 'post published',
    },
    tag_ids: post.tagIds,
  };
};

export const uouPublishPostSuccessEvent = { [PUBLISH_POST_SUCCESS]: handler };
